<template>
    <div class="Logo">
        <img src="https://static.wixstatic.com/media/ebb861_ca5229aa420e4138851ae495ce1fafca.png/v1/fill/w_127,h_132,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ebb861_ca5229aa420e4138851ae495ce1fafca.png" alt="">
        <h1>LA CANTINA</h1>
    </div>
</template>

<script>

export default {
    name: "LogoTitle"
}
</script>

<style>
.Logo {
    width: 100%;
    height: 200px;
    margin: 2rem 0;
}

.Logo h1 {
    color: #EA2306;
}
</style>